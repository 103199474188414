
import { defineComponent, ref, unref, toRefs, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage, ElForm } from 'element-plus';

import { Rules } from 'async-validator';
import useFormErrors from '@/utils/validate';
import ImageUploader, { UploadImageEvent } from '@/components/ImageUploader.vue';

import { useCreateDeal, useUpdateDeal, useDeal } from '@/composables/api';
import { PartialDeal } from '@/interfaces/Deal';

/* eslint-disable @typescript-eslint/camelcase */
const DEFAULT_FORM_VALUES: PartialDeal = {
  name: '',
  description: '',
  link: '',
  image: '',
  discount: 0
};

const rules: Rules = {
  name: [
    {
      required: true
    }
  ],
  link: [
    {
      required: true,
      type: 'url'
    }
  ],
  discount: [
    {
      required: true
    }
  ]
};

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ImageUploader
  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const dealId = useRoute().params.id as string;
    const router = useRouter();

    const formRef = ref<typeof ElForm>();
    const formValues = ref({ ...DEFAULT_FORM_VALUES });
    const { isLoading: isCreatedLoading, mutate: create } = useCreateDeal();
    const { isLoading: isUpdatedLoading, mutate: update } = useUpdateDeal();
    const { formErrors, bindFormItemError } = useFormErrors();

    const { data, dataUpdatedAt } = useDeal({ dealId }, {
      enabled: isEdit.value,
      refetchOnWindowFocus: !isEdit.value
    });
    watch(dataUpdatedAt, () => {
      formValues.value = data.value.data;
    });

    const handleCoverCrop = ({ path }: UploadImageEvent) => {
      formValues.value.image = path;
    };

    const submitForm = () => {
      formErrors.value = {};
      const form = unref(formRef);

      form &&
        form.validate((valid: boolean) => {
          if (valid) {
            if (isEdit.value) {
              update(
                { dealId, data: formValues.value },
                {
                  onSuccess() {
                    ElMessage.success({
                      message: 'success!',
                      type: 'success'
                    });

                    router.go(-1);
                  },
                  onError(error: any) {
                    ElMessage.error({
                      message: error.response?.data.message,
                      type: 'error'
                    });
                    formErrors.value = error.response?.data.errors;
                  }
                }
              );

              return;
            }

            create(
              { data: formValues.value },
              {
                onSuccess() {
                  ElMessage.success({
                    message: 'success!',
                    type: 'success'
                  });

                  formValues.value = DEFAULT_FORM_VALUES;
                  router.replace({
                    name: 'list-deals'
                  });
                },
                onError(error: any) {
                  ElMessage.error({
                    message: error.response?.data.message,
                    type: 'error'
                  });
                  formErrors.value = error.response?.data.errors;
                }
              }
            );
          }
        });
    };

    return {
      formValues,
      formRef,
      rules,
      handleCoverCrop,
      isCreatedLoading,
      isUpdatedLoading,
      submitForm,
      formErrors,
      bindFormItemError
    };
  }
});
